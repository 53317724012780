<template>
  <div class="footer">
    <div class="footer_top">
      <div class="footer_top_item" v-for="(val, key) in list" :key="key">
        <div>{{ val.title }}</div>
        <ul>
          <li
            v-for="(item, index) in val.content"
            :key="index"
            :style="!item.icon ? 'cursor: pointer' : ''"
          >
            <svg v-if="item.icon" class="icon" aria-hidden="true">
              <use :xlink:href="`#icon-${item.icon}`" />
            </svg>
            <a
              :class="item.path && 'has-path'"
              @click="toRoute(item.path)"
              :href="item.path"
              >{{ item.infor || item.name }}</a
            >
          </li>
        </ul>
      </div>
      <div class="footer_top_content">
        <div class="footer_top_content_img" @click="toRoute('/')">
          <img src="@/assets/images/footer/logo.svg" alt="" />
        </div>
        <div class="footer_up_content_img" @click="toRoute('/')">
          <img src="@/assets/images/footer/qrcode.png" alt="" />
          <p>
            {{ $t('components.Footer.5ohzinh7ivc0') }}<br />
            {{ $t('components.Footer.5ohzinh7jvo0') }}
          </p>
        </div>
      </div>
    </div>
    <div class="footer_bottom">
      <a href="/serviceTerms">{{ $t('components.Footer.5m6kr4zy4to0') }}</a>
      <div>
        <a href="/serviceTerms?isPolicy=2">{{
          $t('components.Footer.5m6kr4zy4wo0')
        }}</a>
      </div>
      <div>
        Copyright©2022-<span>{{ new Date().getFullYear() }}</span>
        {{ $t('components.Footer.443368-0') }}
        {{ $t('components.Footer.443368-1') }}
      </div>
    </div>
  </div>
</template>

<script>
import { offerLocking } from '@/http/home'
export default {
  data() {
    return {
      email: '',
      list: [
        {
          title: this.$t('components.Footer.5m6kr4zy53k0'),
          content: [
            {
              name: this.$t('components.Footer.5m6kr4zy56g0'),
              path: '/plans/propagate'
            },
            {
              name: this.$t('components.Footer.5m6kr4zy59k0'),
              path: '/plans/global'
            },
            {
              name: this.$t('components.Footer.5m6kr4zy5cs0'),
              path: '/activation/first'
            }
          ]
        },
        {
          title: this.$t('components.Footer.5m6kr4zy5fk0'),
          content: [
            {
              name: this.$t('components.Footer.5m6kr4zy5is0'),
              path: '/personal/pay/changeAccount'
            },
            {
              name: this.$t('components.Footer.5m6kr4zy5mc0'),
              path: '/personal/businessManegement/bundleChange'
            },
            {
              name: this.$t('components.Footer.5m6kr4zy5po0'),
              path: '/personal/businessManegement/flowPacket'
            },
            {
              name: this.$t('components.Footer.5m6kr4zy5sg0'),
              path: '/personal/businessManegement/roamPack'
            },
            {
              name: this.$t('components.Footer.5m6kr4zy5vc0'),
              path: '/personal/doubleNumber/publicity'
            }
          ]
        },
        {
          title: this.$t('components.Footer.5m6kr4zy5yg0'),
          content: [
            {
              name: this.$t('components.Footer.5m6kr4zy61g0'),
              path: '/helpSupport/commonProblem'
            },
            {
              name: this.$t('components.Footer.886796-0'),
              path: '/videoSupport'
            },
            {
              name: this.$t('components.Footer.5m6kr4zy64c0'),
              path: '/coverage'
            },
            {
              name: this.$t('components.Footer.5m6kr4zy64c1'),
              path: '/phone-detection'
            },
            {
              name: this.$t('components.Footer.5m6kr4zy67g0'),
              path: '/tracking'
            },
            {
              name: this.$t('components.Footer.5m6kr4zy6bk0'),
              path: '/rates'
            }
          ]
        },
        {
          title: this.$t('components.Footer.5m6kr4zy6e40'),
          content: [
            {
              name: this.$t('components.Footer.5m6kr4zy6h40'),
              path: '/aboutUs'
            },
            {
              name: this.$t('components.Footer.5m6kr4zy6jo0'),
              path: '/aboutUs/contactUs'
            },
            {
              name: this.$t('components.Footer.886796-1'),
              path: '/store'
            },
            {
              name: this.$t('components.Footer.886796-2'),
              path: '/agent'
            },

            {
              name: this.$t('components.Footer.5m6kr4zy6j30'),
              // path:
              //   sessionStorage.getItem('lang') == 'en' ? '/blog/en/' : '/blog/'
              path: '/blog'
            }
          ]
        },
        {
          title: this.$t('components.Footer.5m6kr4zy6ls0'),
          content: [
            {
              icon: 'email',
              infor: this.$t('components.Footer.5m6kr4zy6o00')
            },
            {
              icon: 'usa',
              infor: this.$t('components.Footer.5m6kr4zy6q80')
            },
            {
              icon: 'china',
              infor: this.$t('components.Footer.5m6kr4zy6sc0')
            },
            {
              icon: 'enc',
              infor: this.$t('components.Footer.5m6kr4zy6uk0')
            }
          ]
        }
      ]
    }
  },
  methods: {
    toRoute(path) {
      if (path.includes('blog')) {
        window.location.href = path
      }

      this.$router.push({
        path: path
      })
    },
    offerLocking() {
      if (!this.email) {
        this.$message.error(this.$t('components.Footer.5m6kr4zy6wo0'))
        return
      } else if (
        !/^([a-zA-Z0-9_.-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/.test(this.email)
      ) {
        this.$message.error(this.$t('components.Footer.5m6kr4zy6ys0'))
        return
      }
      const params = {
        email: this.email,
        collectType: '1',
        channel: 'pc'
      }
      offerLocking(params).then((res) => {
        if (res.code === '200') {
          this.email = ''
          this.$message.success(res.message)
        } else {
          this.$message.error(res.message)
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.footer {
  width: 100%;
  background-color: #f8f8fa;
  &_top {
    display: flex;
    justify-content: space-between;
    padding: 40px 20px;
    max-width: 1280px;
    margin: 0 auto;
    box-sizing: border-box;

    &_item {
      // margin-right: 60px;

      &:nth-child(n + 4) {
        // margin-right: 130.5px;
      }

      div {
        position: relative;
        margin-bottom: 34px;

        font: {
          size: 16px;
          weight: 600;
        }

        color: #21a56c;
        line-height: 22px;

        &::after {
          position: absolute;
          left: 0;
          bottom: -10px;
          width: 24px;
          height: 2px;
          background: #fcba1d;
          border-radius: 30px;
          content: '';
        }
      }

      ul {
        li {
          display: flex;
          align-items: center;
          font-size: 14px;
          line-height: 20px;
          color: rgba(0, 0, 0, 0.85);

          &:not(:last-child) {
            margin-bottom: 16px;
          }

          .icon {
            width: 24px;
            height: 24px;
            margin-right: 8px;
          }

          a {
            text-decoration: none;
          }

          a.has-path {
            transition: color 0.3s ease;

            &:hover {
              text-decoration: underline;
              color: #21a56c;
            }
          }
        }
      }
    }

    &_content {
      &_title {
        font: {
          size: 16px;
          weight: 500;
        }

        line-height: 22px;
        color: rgba(0, 0, 0, 0.85);
      }

      &_describ {
        margin: 4px 0 16px;
        font-size: 12px;
        line-height: 17px;
        color: rgba(0, 0, 0, 0.45);
      }

      &_img {
        width: 240px;
        height: 56px;
        cursor: pointer;
      }
    }
  }

  &_bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 0;
    background: #30b27a;
    font-size: 14px;
    color: #fff;
    line-height: 18px;

    a {
      margin-right: 10px;
      color: #fff;

      &:last-of-type {
        margin-right: 20px;
      }
    }

    div {
      padding-left: 20px;
      border-left: 1px solid #fff;
    }
  }
  .footer_up_content_img {
    text-align: center;
    img {
      height: 120px;
      width: 120px;
    }
  }
}

@media screen and (max-width: 767px) {
  .footer {
    min-width: 100%;

    .footer_top {
      display: block;
      overflow: hidden;

      .footer_top_item {
        width: 50%;
        float: left;
        display: inline;
        margin-right: 0;
        height: 66.6667vw;
        padding: 0 5.3333vw;
        box-sizing: border-box;
      }

      & > div {
        &:nth-child(3) {
          margin-top: -21.3333vw;
          height: 54vw;
        }

        &:nth-child(4) {
          margin-top: -2vw;
          height: 54vw;
        }

        &:nth-child(5) {
          margin-top: -20vw;
          height: 60vw;
          width: 100%;
        }
      }

      ::v-deep .el-input__prefix {
        display: inline-block;
        margin-top: 7px;
      }
    }

    .footer_top_content {
      float: left;
      display: inline;
      width: 100%;
      padding: 0 5.3333vw;
      box-sizing: border-box;
      text-align: center;
    }

    .footer_top_content_img {
      display: none;
    }
  }
}
</style>
<style lang="scss">
.footer {
  .el-input {
    font-size: 16px;

    &__inner {
      height: 38px;
      border: none;
      line-height: 38px;
      border-radius: 80px;
    }

    &__prefix {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__suffix {
      right: 0;
    }

    .icon {
      width: 24px;
      height: 24px;
    }

    button {
      width: 96px;
      height: 38px;
      background: #fcba1d;
      border-color: #fcba1d;
      border-radius: 80px;
    }

    button:hover,
    button:focus {
      background: #fcba1d;
      border-color: #fcba1d;
    }

    button {
      background: #eba83b;
      border-color: #eba83b;
    }
  }
}
</style>
