import axios from 'axios'
import { MessageBox, Message, Loading } from 'element-ui'
import route from '@/router/index'
import { getToken } from '@/utils/auth'
import codeMap from '@/utils/codeMap'
import errorCode from '@/utils/errorCode'

let loading

function startLoading(loadingText) {
  //使用Element loading-start 方法
  loading = Loading.service({
    lock: true,
    text: loadingText || '加载中……',
    spinner: 'el-icon-loading',
    background: 'rgba(0, 0, 0, 0.7)',
    customClass: 'loadingclass'
  })
}
function endLoading() {
  //使用Element loading-close 方法
  loading && loading.close()
}
let needLoadingRequestCount = 0
export function showFullScreenLoading(loadingText) {
  if (needLoadingRequestCount === 0) {
    startLoading(loadingText)
  }
  needLoadingRequestCount++
}

export function tryHideFullScreenLoading() {
  if (needLoadingRequestCount <= 0) return
  needLoadingRequestCount--
  if (needLoadingRequestCount === 0) {
    endLoading()
  }
}
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 对应国际化资源文件后缀
axios.defaults.headers['Content-Language'] = 'zh_CN'
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分

  baseURL: '/',
  // 超时
  timeout: 600000
})
// request拦截器
service.interceptors.request.use(
  (config) => {
    if (getToken()) {
      config.headers['Authorization'] = 'Bearer ' + getToken()
      config.headers['Content-Type'] =
        window.localStorage.getItem('contentType') != null &&
        window.localStorage.getItem('contentType') == 'form'
          ? 'application/x-www-form-urlencoded'
          : 'application/json;charset=utf-8'
    }

    //canting 特定活动
    if (route.history.current.fullPath.indexOf('canting') != -1) {
      sessionStorage.setItem('promotion', 'canting')
    }

    // 活动
    if (route.history.current.fullPath.indexOf('platform=') != -1) {
      sessionStorage.setItem(
        'promotion',
        route.history.current.query.platform || ''
      )
    }
    if (route.history.current.fullPath.indexOf('activity_code=') != -1) {
      sessionStorage.setItem(
        'activity_code',
        route.history.current.query.activity_code || ''
      )
    }

    config.headers['PLAT-FORM'] = sessionStorage.getItem('promotion')
    config.headers['ACTIVITY-CODE'] = sessionStorage.getItem('activity_code')
    config.headers['CHANNEL'] = 'eshop'
    config.headers['Language'] = sessionStorage.getItem('lang')

    // get请求映射params参数
    if (config.method === 'get' && config.params) {
      let url = config.url + '?'
      for (const propName of Object.keys(config.params)) {
        const value = config.params[propName]
        var part = encodeURIComponent(propName) + '='
        if (value !== null && typeof value !== 'undefined') {
          if (typeof value === 'object') {
            for (const key of Object.keys(value)) {
              if (value[key] !== null && typeof value[key] !== 'undefined') {
                let params = propName + '[' + key + ']'
                let subPart = encodeURIComponent(params) + '='
                url += subPart + encodeURIComponent(value[key]) + '&'
              }
            }
          } else {
            url += part + encodeURIComponent(value) + '&'
          }
        }
      }
      url = url.slice(0, -1)
      config.params = {}
      config.url = url
    }
    if (config.method === 'post' && config.data) {
      if (
        window.localStorage.getItem('contentType') == null ||
        window.localStorage.getItem('contentType') == ''
      ) {
        config.data = JSON.stringify(config.data)
      }
    }
    if (config.noLoading) {
      //Loading 不加在 验证码图片接口上
      endLoading()
    } else {
      let loadingText = config.loadingText || '加载中……'
      startLoading(loadingText)
    }
    return config
  },
  (error) => {
    endLoading()
    Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  (res) => {
    // return res.data
    const code = res.data.code
    // 获取错误信息
    const msg = res.data.message || res.error
    const keys = Object.keys(errorCode)
    if (keys.includes(code) && code !== '120087') {
      // 弹框提示
      MessageBox.confirm(msg, '提示', {
        showCancelButton: false,
        type: 'warning'
      }).catch(() => {})
      endLoading()
    } else if (code === '120087') {
      // 订单查询过期统一拦截
      MessageBox.confirm(msg, '提示', {
        showCancelButton: false,
        type: 'warning',
        message: '支付信息已过期，请点击"确定"以返回首页',
        confirmButtonText: '确定'
      })
        .then((action) => {
          if (action === 'confirm') {
            route.replace({ path: '/' })
          }
        })
        .catch(() => {})
      endLoading()
    }
    // 未设置状态码则默认成功状态
    endLoading()
    window.localStorage.setItem('contentType', '')
    return res.data
  },
  (err) => {
    endLoading()
    window.localStorage.setItem('contentType', '')
    if (err?.response) {
      const response = err.response
      const errCode = response.error_description
      const httpCode = response.status
      if (errCode) {
        const errorMsg = codeMap[errCode]
        Message({
          message: errorMsg,
          type: 'error',
          duration: 5 * 1000
        })
      } else {
        const httpMsg = codeMap[httpCode]
        if (httpCode == 401 || httpCode == 403) {
          route.replace({ path: '/login' })
        } else {
          Message({
            message: httpMsg,
            type: 'error',
            duration: 5 * 1000
          })
        }
      }
    } else {
      return Promise.reject(err)
    }
  }
)

export default service
